import React, { ReactNode } from 'react';

import styled from 'styled-components';

import iconRightArrow from 'stile-shared/assets/icons/icon_right_arrow.svg';

import { Button } from 'stile-shared/src/components/2024/common/Button';
import { Icon } from 'stile-shared/src/components/2024/common/Icon';

const StyledExpertAssistanceSection = styled.div`
  padding: 150px 0;
  background-color: var(--white);
  @media (max-width: 768px) {
    padding: 0 0 60px 0;
  }
  .content {
    border-radius: 32px;
    overflow: hidden;
    box-shadow: 0px 531.667px 149.028px 0px rgba(0, 0, 0, 0),
      0px 339.944px 136.139px 0px rgba(0, 0, 0, 0.01),
      0px 191.722px 115.194px 0px rgba(0, 0, 0, 0.03), 0px 85.389px 85.389px 0px rgba(0, 0, 0, 0.04),
      0px 20.944px 46.722px 0px rgba(0, 0, 0, 0.05);

    @media (max-width: 768px) {
      box-shadow: none;
      background-color: var(--white);
    }

    > div {
      padding: 60px;
      border-radius: 32px;
      border: 0.806px solid var(--stile-grey-1);
      background-color: var(--stile-grey-1);
      position: relative;
      overflow: hidden;

      display: flex;
      flex-direction: row;
      gap: 52px;

      @media (max-width: 768px) {
        background-color: var(--white);
        flex-direction: column-reverse;
        padding: 25px 25px 30px 25px;
        gap: 25px;
      }

      .--left,
      .--right {
        flex-basis: 50%;
        @media (max-width: 768px) {
          flex-basis: 100%;
        }
      }

      .--left {
        justify-items: center;
        align-content: center;
        .--headings {
          display: flex;
          flex-direction: column;
          gap: 22px;
          @media (max-width: 768px) {
          }
          .h9 {
            margin: 0;
            padding: 0;
          }
          h4 {
            margin: 0;
            padding: 0;
          }
        }
        .--copy {
          display: flex;
          flex-direction: column;
          gap: 10px;
          p {
            width: 71%;
            margin: 25px 0 15px 0;

            @media (max-width: 768px) {
              width: 100%;
              margin: 12px 0 20px 0;
            }
          }
          .button {
            div {
              margin-left: 6px;
            }
            &.mobile {
              display: none;

              @media (max-width: 768px) {
                display: flex;
              }
            }

            @media (max-width: 768px) {
              &.desktop {
                display: none;
              }
            }
          }
        }
      }

      .--right {
        .gatsby-image-wrapper {
          border-radius: 32px;
          @media (max-width: 768px) {
            width: 100%;
          }
        }
      }
    }
  }
`;

type ExpertAssistanceProps = {
  heading: string;
  copy: string;
  href: string;
  expertImage: ReactNode;
} & React.HTMLAttributes<HTMLDivElement>;

export default function ExpertAssistance({
  heading: header,
  copy,
  href,
  expertImage,
}: ExpertAssistanceProps) {
  return (
    <StyledExpertAssistanceSection>
      <div className="content">
        <div>
          <div className="--left">
            <div className="--headings">
              <h4>{header}</h4>
            </div>
            <div className="--copy">
              <p>{copy}</p>
              <Button
                href={href}
                label={
                  <>
                    <span>Learn more about Stile’s supported pilots</span>
                    <Icon src={iconRightArrow} iconSize="fontSize" />
                  </>
                }
              />
              <Button
                external={false}
                href="/set-up-trial/"
                label={
                  <>
                    <span>Start a conversation with us</span>
                  </>
                }
              />
            </div>
          </div>
          <div className="--right">{expertImage}</div>
        </div>
      </div>
    </StyledExpertAssistanceSection>
  );
}
