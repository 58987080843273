import * as React from 'react';

import './Slider.css';

type SliderProps = React.HTMLAttributes<HTMLDivElement> & {
  tabs: string[];
  headings: string[];
  paragraphs: string[];
  whatYouWillLearn?: string[][];
  images: React.ReactNode[];
};

/**
 * Displays Slider
 */
export const Slider = ({
  tabs,
  headings,
  paragraphs,
  whatYouWillLearn = undefined,
  images,
  ...props
}: React.PropsWithChildren<SliderProps>) => {
  const [selectedTab, setSelectedTab] = React.useState(0);

  function setTabAndScroll(index: number, event: React.MouseEvent) {
    setSelectedTab(index);
    // setTimeout(() => {
    //   (event?.target as HTMLDivElement)?.scrollIntoView({
    //     block: 'start',
    //     behavior: 'smooth',
    //   });
    // }, 100);
  }
  return (
    <div className="slider" {...props}>
      <div className="--header">
        {tabs.map((tab, i) => (
          <button
            key={i}
            aria-roledescription="tab"
            role="tab"
            className={`--headerButton ${selectedTab === i ? '--active' : ''}`}
            onClick={(e) => setTabAndScroll(i, e)}
          >
            <h6 className="h7">{tab}</h6>
          </button>
        ))}
      </div>

      {[0, 1, 2, 3].map((i) => (
        <div key={i} className="--content-container">
          <button
            aria-roledescription="tab"
            role="tab"
            className={`--headerButton ${selectedTab === i ? '--active' : ''}`}
            onClick={(e) => setTabAndScroll(i, e)}
          >
            <h6 className="h8">{tabs[i]}</h6>

            {selectedTab !== i && (
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M10 5.25C10.4142 5.25 10.75 5.58579 10.75 6L10.75 14C10.75 14.4142 10.4142 14.75 10 14.75C9.58579 14.75 9.25 14.4142 9.25 14L9.25 6C9.25 5.58579 9.58579 5.25 10 5.25Z"
                  fill="#DAD4CC"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M14.75 10C14.75 10.4142 14.4142 10.75 14 10.75L6 10.75C5.58579 10.75 5.25 10.4142 5.25 10C5.25 9.58579 5.58579 9.25 6 9.25L14 9.25C14.4142 9.25 14.75 9.58579 14.75 10Z"
                  fill="#DAD4CC"
                />
              </svg>
            )}
          </button>
          <div className={`--content ${i === selectedTab ? '--active' : ''}`}>
            <div className="--text">
              <h4>{headings[i]}</h4>
              <p>{paragraphs[i]}</p>
              {whatYouWillLearn && (
                <>
                  <p className="whatYoullLearn">What you’ll learn:</p>
                  <ul>
                    {i === selectedTab &&
                      (whatYouWillLearn[i] || []).map((text, index) => <li key={index}>{text}</li>)}
                  </ul>
                </>
              )}
            </div>
            <div className="--image">{images[i]}</div>
          </div>
        </div>
      ))}
    </div>
  );
};
