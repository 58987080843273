import styled from 'styled-components';

export const TopSection = styled.div`
  background-color: var(--stile-grey-1);
  @media (max-width: 768px) {
    background-color: transparent;
  }

  .content {
    display: flex;
    flex-direction: row;
    position: relative;
    padding-bottom: 50px; // different
    justify-content: space-between;

    @media (max-width: 768px) {
      flex-direction: column;
      padding-bottom: 0;
      margin-top: 28px;
    }

    .--left {
      width: 45%;
      h1 {
        margin-top: 80px;
        margin-bottom: 60px;
      }

      @media (max-width: 768px) {
        width: 100%;
        h1 {
          margin-top: 0;
          margin-bottom: 25px;
        }
      }
    }

    .--right {
      width: 50%;
      .gatsby-image-wrapper {
        margin-top: 70px;
      }

      @media (max-width: 768px) {
        .gatsby-image-wrapper {
          margin-top: 30px;
          margin-bottom: 45px;
        }
        width: 100%;
      }
    }

    h6,
    ul {
      margin: 0;
      padding: 0;
      width: 75%;

      @media (max-width: 768px) {
        width: 100%;
      }
    }

    .underline {
      margin-top: 20px; // different
      margin-bottom: 10px; // different
    }

    ul {
      width: 90%;
      list-style: none;
      padding-left: 0;
      margin: 0;
      li {
        display: flex;
        align-items: start;
        margin-top: 20px;
        img {
          margin-right: 12px;
          margin-top: 2px;
        }
      }
    }
  }

  @keyframes hearts {
    0% {
      opacity: 0;
      transform: translate(0, 0%) rotate(45deg);
    }
    20% {
      //show and hint at moving
      opacity: 0.8;
      transform: translate(0, -20%) rotate(45deg);
    }
    100% {
      opacity: 0;
      transform: translate(0, -1000%) rotate(45deg); //Big hearts move faster
    }
  }
  .hearts {
    position: relative;
    .content {
      position: relative;
      padding: 0;

      > .particle {
        opacity: 0;
        position: absolute;
        background-color: var(--stile-green-1);
        animation: hearts 3s ease-in infinite;
        &:before,
        &:after {
          position: absolute;
          content: '';
          border-radius: 100px;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
          background-color: var(--stile-green-1);
        }
        &:before {
          transform: translateX(-50%);
        }
        &:after {
          transform: translateY(-50%);
        }
      }
    }
  }
`;
