import React, { ReactNode } from 'react';

import styled from 'styled-components';

import iconCircle from 'stile-shared/assets/icons/icon_circle.svg';

import { Section } from 'stile-shared/src/components/2024/layout/Section';

const StyledScienceTestSection = styled<typeof Section>(Section)`
  .content {
    padding: 150px 0;

    @media (max-width: 768px) {
      padding: 60px 0 !important;
    }

    .grid-layout {
      margin-top: 0 !important;
    }

    h2 {
      margin: 0 0 32px 0 !important;
      @media (max-width: 768px) {
        text-align: left !important;
        margin: 0 0 20px 0 !important;
      }
    }

    .--subheader {
      grid-column: 4 / span 6;
      text-align: center;
      margin-bottom: 120px;
      @media (max-width: 768px) {
        grid-column: 1 / span 12;
        margin-bottom: 60px;
        text-align: left !important;
      }
    }

    .--side-by-side {
      grid-column: span 12;
      display: flex;
      flex-direction: row;
      gap: 80px;
      justify-content: center;

      @media (max-width: 768px) {
        flex-direction: column;
        gap: 40px;
      }

      .--left,
      .--right {
        width: 35%;
        background-color: var(--white);
        border-radius: 32px;
        position: relative;
        padding: 80px 40px 50px 50px;
        display: flex;
        flex-direction: column;
        box-shadow: 0 0 10px 10px #fff;

        @media (max-width: 768px) {
          width: 100%;
          box-shadow: none;
          padding: 50px 25px 50px 25px;
        }

        .h8 {
          padding: 0;
          margin: 0;
        }
        .--rounded-heading {
          border-radius: 32px;
          color: var(--white);
          background-color: var(--stile-grey-8);
          position: absolute;
          display: inline-flex;
          padding: 9px 20px 10px 20px;
          justify-content: center;
          align-items: center;

          // position it top center but half way up itself
          top: 5%; // different
          left: 40%; // different
          transform: translate(-30%, -150%); // different
          @media (max-width: 768px) {
            top: 7%; // different
          }
        }
        p {
          margin: 10px 0 30px 0;
          @media (max-width: 768px) {
            margin-bottom: 30px; // different
          }

          padding: 0;
          ol {
            list-style: upper-alpha;
            padding-left: 20px;
            margin-top: 30px;
            margin-bottom: 0;
            li {
              padding-left: 20px;
              margin-bottom: 15px;
              &:last-child {
                margin-bottom: 0;
              }
            }
          }

          ul {
            padding-left: 0px;
            margin-top: 40px;
            list-style: none;
            display: flex;
            flex-direction: column;
            gap: 15px;
            li {
              display: flex;
              background: url(${iconCircle}) no-repeat left top;
              padding-left: 30px;
            }
          }
        }

        .--spacer {
          flex-grow: 1;
        }
      }

      .--left {
        .--rounded-heading {
          @media (max-width: 768px) {
            left: 30% !important;
            transform: translate(-20%, -150%) !important;
          }
        }
      }

      .--right {
        .--rounded-heading {
          background-color: var(--stile-green-2);
        }
      }
    }
  }
`;

type ScienceTestSectionProps = {
  heading: string;
  subHeading: string;
  leftHeading: string;
  leftContent: ReactNode;
  leftImage?: ReactNode;
  rightHeading: string;
  rightContent: ReactNode;
  rightImage?: ReactNode;
  className?: string;
} & React.HTMLAttributes<HTMLDivElement>;

export default function ScienceTestSection({
  heading,
  subHeading,
  leftHeading,
  leftContent,
  leftImage,
  rightHeading,
  rightContent,
  rightImage,
  className,
  ...props
}: ScienceTestSectionProps) {
  return (
    <StyledScienceTestSection
      headingContent={heading}
      backgroundColor="--stile-grey-1"
      className={className}
      {...props}
    >
      <>
        <div className="--subheader">{subHeading}</div>
        <div className="--side-by-side">
          <div className="--left">
            <h6 className="h8 --rounded-heading">{leftHeading}</h6>
            <h6 className="h8">Question</h6>
            <p className="--small">{leftContent}</p>
            <div className="--spacer" />
            {leftImage}
          </div>
          <div className="--right">
            <h6 className="h8 --rounded-heading">{rightHeading}</h6>
            <h6 className="h8">Question</h6>
            <p className="--small">{rightContent}</p>
            <div className="--spacer" />
            {rightImage}
          </div>
        </div>
      </>
    </StyledScienceTestSection>
  );
}
