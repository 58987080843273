import React from 'react';

import styled from 'styled-components';

import { Slider } from 'stile-shared/src/components/2024/common/Slider';
import { Section } from 'stile-shared/src/components/2024/layout/Section';

const StyledSection = styled<typeof Section>(Section)`
  h2 {
    margin: 0 0 32px 0 !important;
    @media (max-width: 768px) {
      margin-bottom: 20px !important;
      text-align: left !important;
    }
  }

  @media (max-width: 768px) {
    padding: 60px 0 0 0;
    .content {
      padding-top: 0 !important;
    }
  }

  .--subheader {
    grid-column: 4 / span 6;
    text-align: center;
    margin-bottom: 80px;
    @media (max-width: 768px) {
      grid-column: 1 / span 12;
      margin-bottom: 40px;
      text-align: left !important;
    }
  }

  .showcase-content {
    grid-column: span 12;
    padding-bottom: 150px;
    @media (max-width: 768px) {
      padding-bottom: 0px;
    }
  }
  .grid-layout {
    margin-top: 0 !important;
    @media (max-width: 768px) {
      margin-top: 0 !important;
      padding-bottom: 70px;
    }
  }
`;

type RealWorldSectionProps = {
  heading: string;
  subheading: string;
  tabs: string[];
  headings: string[];
  paragraphs: string[];
  whatYouWillLearn?: string[][];
  images: React.ReactNode[];
} & React.HTMLAttributes<HTMLDivElement>;

export default function RealWorldSection({
  heading,
  subheading,
  tabs,
  headings,
  paragraphs,
  whatYouWillLearn,
  images,
}: RealWorldSectionProps) {
  return (
    <StyledSection
      backgroundColor="--stile-green-2"
      headingColor="--stile-grey-8"
      headingContent={heading}
      textColor="--stile-grey-8"
    >
      <div className="--subheader">{subheading}</div>
      <div className="showcase-content">
        <Slider
          tabs={tabs}
          headings={headings}
          paragraphs={paragraphs}
          whatYouWillLearn={whatYouWillLearn}
          images={images}
        />
      </div>
    </StyledSection>
  );
}
