import React from 'react';

import styled from 'styled-components';

import { Grid } from 'stile-shared/src/components/2024/layout/Grid';

const StyledDiv = styled.div<{ callOutBottomSize?: string }>`
  background-color: var(--white);
  .content {
    padding: 150px 0;

    @media (max-width: 768px) {
      padding: 60px 0;
    }

    .grid-layout {
      gap: 0;
      .left,
      .right {
        position: relative;
        .gatsby-image-wrapper {
          border-radius: 32px;
          aspect-ratio: 1;
        }

        h2,
        p {
          padding: 0;
          margin: 0;
          margin-bottom: 32px;
        }

        @media (max-width: 768px) {
          h2 {
            margin-bottom: 20px;
          }
        }

        .--buttons {
          a {
            @media (max-width: 768px) {
              width: 100%;
              text-align: center;
              justify-content: center;
            }

            &.--alignment {
              padding-left: 10px;
              padding-right: 10px;
            }
          }
          a:first-child {
            margin-bottom: 12px;
          }
        }

        .--text {
          margin-bottom: 32px !important;
        }
      }
      .left {
        grid-column: 1 / span 6;

        .--callout {
          width: 140px;
          height: 140px;
          position: absolute;
          top: -70px;
          right: -70px;
          border-radius: 50%;
          background-color: var(--stile-green-1);
          color: var(--stile-grey-8);
          padding: 24px 14px 40px 15px;
          strong {
            font-family: 'More Sugar';
            font-size: 63.83px;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -2.553px;
          }
          span {
            display: block;
            text-align: center;
            font-size: ${(props) => props.callOutBottomSize};
            font-weight: 600;
            line-height: 0%;
          }

          @media (max-width: 768px) {
            top: -35px;
            right: 35px;
            width: 100px;
            height: 100px;
            padding: 15px 8px 28px 9px;
            text-align: center;
            margin-left: -4px;

            strong {
              text-align: center;
              font-size: 41.5px;
              line-height: normal;
              letter-spacing: -1.893px;
            }
            span {
              font-size: 10.449px;
              line-height: 12.287px; /* 153.59% */
            }
          }
        }

        @media (max-width: 768px) {
          grid-column: 1 / span 12;
        }
      }
      .right {
        grid-column: 8 / span 5;

        @media (max-width: 768px) {
          grid-column: 1 / span 12;
          grid-row: 2;
          margin-top: 30px;
        }
      }
    }
  }
`;

type AlignedSectionProps = {
  image: React.ReactNode;
  callOutTop: string;
  callOutBottom: string;
  callOutBottomSize?: string;
  heading: string;
  body: React.ReactNode;
  buttons: React.ReactNode[];
} & React.HTMLAttributes<HTMLDivElement>;

export default function AlignedSection({
  image,
  callOutTop,
  callOutBottom,
  callOutBottomSize = '10.426px',
  heading,
  body,
  buttons,
  ...props
}: AlignedSectionProps) {
  return (
    <StyledDiv callOutBottomSize={callOutBottomSize} {...props}>
      <div className="content">
        <Grid>
          <div className="left">
            {image}
            <div className="--callout">
              <strong>{callOutTop}</strong>
              <span>{callOutBottom}</span>
            </div>
          </div>
          <div className="right">
            <h2>{heading}</h2>
            <div className="--text">{body}</div>
            <div className="--buttons">{buttons}</div>
          </div>
        </Grid>
      </div>
    </StyledDiv>
  );
}
